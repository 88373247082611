
import Vue from "vue";
import { ILinks } from "../store/state";

// Interfaces for component typechecking!
interface DropDownData {
  isExpanded: boolean,
  btnText: string
}

interface DropDownMethods {
  onChange(item: ILinks): void;
  toggleList(): void;
}

interface DropDownComputed {
  items: ILinks[],
}

interface DropDownProps {}

export default Vue.extend<DropDownData, DropDownMethods, DropDownComputed, DropDownProps>({
  name: "DropDown",
  data() {
    return {
      isExpanded: false,
      btnText: "E-tjänster och Mina sidor",
    };
  },
  computed: {
    items () {
			return this.$store.getters.items;
		},
  },
  methods: {
    toggleList() {
			this.isExpanded = !this.isExpanded;
		},
    onChange(item: ILinks) {
      this.isExpanded = false;
      this.btnText = item.name;
      window.location.href = item.url;
    },
  },
});
