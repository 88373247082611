import { MutationTree } from 'vuex';
import { ILinks, IState } from './state';
import { ActionPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const setSelectedUrlMutation: MutationHandler<ILinks> = (state, { payload }) => {
	state.selectedUrl = payload.url;
	state.items.forEach(item => item.selected = item.url === payload.url);
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	setSelectedUrl: setSelectedUrlMutation
}

export default mutations
