import { Getter } from "vuex";
import { IState } from './state';

const text: Getter<IState, IState> = (state) => state.text;
const items: Getter<IState, IState> = (state) => state.items;


export default {
	text, items
}
