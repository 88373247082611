
import Vue from "vue";
import { ILinks } from "./store/state";
import DropDown from "./components/DropDown.vue";

// Interfaces for component typechecking!
interface AppData {}

interface AppMethods {}

interface AppComputed {
	selectedUrl: string;
}

interface AppProps {}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
  name: "app",
	computed: {
		selectedUrl() {
			return this.$store.state.selectedUrl;
		},
	},
  methods: {
  },
  components: {
		DropDown
	},
});
