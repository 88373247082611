export interface ILinks {
	name: string,
	url: string,
	selected: boolean
}

export interface IState {
	text: string,
	items: ILinks[],
	selectedUrl: string
}

const deafultState: IState = {
	text: '',
	items: [],
	selectedUrl: ''
}

export default deafultState;
